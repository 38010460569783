import React, { useState } from "react";
import Form from "react-bootstrap/Form";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import PropTypes from "prop-types";
import { format } from "date-fns";
//import { isNilOrEmpty } from "../components/CustomUtility";

import "../../src/chatbubble.css";

export default function ResponseComponent({ idrResponse, onValueChange }) {
  ResponseComponent.propTypes = {
    idrResponse: PropTypes.shape({
      idrResponseId: PropTypes.number.isRequired,
      responseType: PropTypes.string.isRequired,
      responseText: PropTypes.string.isRequired,
      defaultValue: PropTypes.string,
    }).isRequired,

    onValueChange: PropTypes.func.isRequired,
  };

  // Access the JSON object
  //const idrResponse = props.data;
  let result;

  // if (responseType === "DatePeriod") {
  //   var separatedDates = responseText.split(" - ");
  // }
  const idrResponseId = idrResponse.idrResponseId;
  const type = idrResponse.responseType;
  const responseText = idrResponse.responseText;
  const defaultValue = idrResponse.defaultValue;

  //const today = new Date();
  const [startDate, setStartDate] = useState(null);
  const [defTextValue, setDefTextValue] = useState(defaultValue);

  const onValueChangeInternal = (event) => {
    setDefTextValue(event.target.value);

    onValueChange(
      {
        target: {
          name: idrResponseId,
          value: event.target.value,
        },
      },
      idrResponse
    );
  };

  // Call the onValueChangeInternal function with the default value
  useState(() => onValueChangeInternal({ target: { value: defaultValue } }));

  const handleDateTimeChange = (date) => {
    setStartDate(date);
    var formattedDate = format(date, "dd/MM/yyyy hh:mm").toString();

    //how get idrResponseId value here?
    var sss = idrResponseId;
    console.log(sss);

    onValueChange(
      {
        target: {
          name: idrResponseId,
          value: formattedDate,
        },
      },
      idrResponse
    );
  };

  switch (type) {
    case "MultipleChoice":
      result = (
        <div>
          <Form.Control
            className=" mt-2"
            style={{
              width: "90%",
              border: "1px solid #8a8a8a",
              fontWeight: "bold",
            }}
            readOnly={true}
            type="text"
            id={idrResponseId}
            name={idrResponseId}
            placeholder={responseText}
          />
        </div>
      );
      break;
    case "Text":
      result = (
        <div>
          <Form.Label className="mb-0 idr-label-text">
            {responseText}
          </Form.Label>
          <Form.Control
            type="text"
            id={idrResponseId}
            name={idrResponseId}
            placeholder={responseText}
            onChange={onValueChangeInternal}
            //defaultValue={isNilOrEmpty(defaultValue) ? "" : defaultValue}
            value={defTextValue}
          />
        </div>
      );
      break;
    case "MultilineText":
      result = (
        <div>
          <Form.Label className="mb-0 idr-label-text">
            {responseText}
          </Form.Label>
          <Form.Control
            as="textarea"
            rows={3}
            id={idrResponseId}
            name={idrResponseId}
            placeholder={responseText}
            onChange={(event) => onValueChange(event, idrResponse)}
          />
        </div>
      );
      break;
    case "Number":
      result = (
        <div>
          <Form.Label className="mb-0 idr-label-text">
            {responseText}
          </Form.Label>
          <Form.Control
            type="number"
            id={idrResponseId}
            name={idrResponseId}
            placeholder={responseText}
            onChange={(event) => onValueChange(event, idrResponse)}
          />
        </div>
      );

      break;
    case "Date":
      result = (
        <div>
          <Form.Label className="mb-0 idr-label-text">
            {responseText}
          </Form.Label>{" "}
          <DatePicker
            id={idrResponseId}
            name={idrResponseId}
            selected={responseText}
          />
        </div>
      );
      break;
    case "Time":
      result = (
        <div>
          <Form.Label className="mb-0 idr-label-text">
            {responseText}
          </Form.Label>
          <DatePicker
            id={idrResponseId}
            name={idrResponseId}
            selected={responseText}
            showTimeSelect
            showTimeSelectOnly
            timeIntervals={15}
            timeCaption="Time"
            dateFormat="hh:mm"
          />
        </div>
      );
      break;
    case "DateTime":
      result = (
        <div>
          <Form.Label className="mb-0 idr-label-text" column sm={2}>
            {responseText}
          </Form.Label>
          <DatePicker
            id={idrResponseId}
            name={idrResponseId}
            placeholder={responseText}
            selected={startDate}
            showTimeSelect
            timeIntervals={15}
            dateFormat="dd/MM/yyyy hh:mm"
            onChange={handleDateTimeChange}
          />
        </div>
      );
      break;
    case "DatePeriod":
      result = (
        <div>
          <DatePicker
            id={idrResponseId}
            name={idrResponseId}
            selectsRange={true}
            // startDate={separatedDates[0]}
            // endDate={separatedDates[1]}
            dateFormat="d MMMM yyyy"
          />
        </div>
      );
      break;
    default:
      result = <div>No option selected</div>;
      break;
  }

  return <div>{result}</div>;
}
