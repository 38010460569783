// funtion to creates CAMS using the IMSP API reset service
export async function createCAMSticket(camsTicketData) {
  try {
    const url =
      process.env.REACT_APP_IMSP_API_ENDPOINT + "/camsservice/createticket";

    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify(camsTicketData)
    };

    const response = await fetch(url, requestOptions);
    if (response.ok) {
      const jsonData = await response.json();
      return jsonData.jobId;
    } else {
      throw new Error("Error: " + response.status + ";" + response.statusText);
    }
  } catch (error) {
    throw new Error(
      "An error occurred creating CAMS ticket.",
      error.message
    );
  }
}


export async function allowTicketCreation(){
  try {
    const url =
      process.env.REACT_APP_IMSP_API_ENDPOINT + "/camsservice/allowticketcreation";

    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json"
      },
    };

    const response = await fetch(url, requestOptions);
    if (response.ok) {
      const bAllowTicketCreation = await response.json();
      return bAllowTicketCreation;
    } else {
      throw new Error("Error: " + response.status + ";" + response.statusText);
    }
  } catch (error) {
    throw new Error(
      "An error occurred calling allowTicketCreation.",
      error.message
    );
  }
}

