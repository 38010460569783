import { React } from "react";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Image from "react-bootstrap/Image";
import ListGroup from "react-bootstrap/ListGroup";

import { useState, useContext, useEffect } from "react";
import { DeviceContext } from "./DeviceContext";

export default function TerminalDevice() {
  const { deviceInfo, findLabelValue } = useContext(DeviceContext);
  const [imageData, setImageData] = useState(null);

  useEffect(() => {
    if (deviceInfo !== null && deviceInfo.productId !== undefined) {
      let imageUri =
        process.env.REACT_APP_IMSP_API_ENDPOINT +
        "/products/image/" +
        deviceInfo.productId;

      // Make the API request to retrieve the image data
      fetch(imageUri)
        .then((response) => response.blob())
        .then((data) => setImageData(URL.createObjectURL(data)))
        .catch((error) => {
          console.error("Error retrieving image data:", error);
        });
    }
  }, [deviceInfo]);

  return (
    <div>
      {deviceInfo ? (
        <Row>
          <Col xs={9}>
            <br></br>
            <br></br>
            <ListGroup variant="flush">
              <ListGroup.Item>
                <b>{findLabelValue("pageHomeTerminalDeviceType")}:</b>{" "}
                {deviceInfo.productName}
              </ListGroup.Item>
              <ListGroup.Item>
                <b>{findLabelValue("pageHomeTerminalClientID")}:</b>{" "}
                {deviceInfo.clientCode}
              </ListGroup.Item>
              {/* <ListGroup.Item>
                <b>{findLabelValue("pageHomeTerminalCountry")}:</b> {deviceInfo.countryCode}
              </ListGroup.Item>
              <ListGroup.Item>
                <b>{findLabelValue("pageHomeTerminalLanguage")}:</b> {deviceInfo.languageCode}
              </ListGroup.Item> */}
            </ListGroup>
          </Col>
          <Col xs={3} className="ml-auto">
            <br></br>
            <Image src={imageData} width="60" alt="terminal" />
          </Col>
        </Row>
      ) : (
        findLabelValue("pageDeviceNotFound")
      )}
    </div>
  );
}
