export async function InitLiveChat(contactAttributes) {

  let contactAttributeToken = null;
  const url = process.env.REACT_APP_IMSP_API_ENDPOINT + "/amazonChat/createAttributeToken";
  const fetchAttributeToken = async (chatAttribute) => {
    const response = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify(chatAttribute)
    });

    try {
      if (response.ok) {
        const jsonData = await response.json();
        contactAttributeToken = jsonData.token;
      }
      if (!response.ok) {
        throw new Error("Error: " + response.status + ":" + response.statusText);
      }
    } catch (error) {
      throw new Error(
        "An error occurred creating chat attribute token.",
        error.message
      );
    }
  }

  await fetchAttributeToken(contactAttributes);
  return contactAttributeToken;
}

export async function StartLiveChat(displayname, chatAttributeToken) {

  if (chatAttributeToken !== '') {
    window.startChatfunction(chatAttributeToken);

    // if(displayname !==''){
    //   window.amazon_connect('customerDisplayName', function (callback) {
    //     callback(displayname);
    //   });
    // }

    // // Authenticate and start the chat session
    // window.amazon_connect('authenticate', function (callback) {
    //   callback(chatAttributeToken);
    // });


    // let chatButton = null;
    // const chatWidget1 = document.getElementById('amazon-connect-chat-widget');
    // // Find the button inside the div
    // chatButton = chatWidget1.querySelector('button');

    // // Get the target element's position
    // var targetElement = document.getElementById("idrHeadSetIconID");
    // var targetRect = targetElement.getBoundingClientRect();

    // // Check if the button exist
    // if (chatButton && targetRect) {

    //   //show chat icon
    //   chatWidget1.style.display = 'block';

    //   // Set the position and height of the button
    //   chatButton.style.position = 'fixed'; // Sets the position to absolute
    //   chatButton.style.width = '45px';
    //   chatButton.style.height = '45px';

    //   chatButton.style.top = targetRect.top - 80 + 'px';
    //   chatButton.style.left = targetRect.right + 20 + 'px';
    //   //chatButton.style.zIndex = "9999";

    //  // chatButton.click(); //startChat
    // }
  }

}