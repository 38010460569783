//create a function thats recieves the email payload and sends it to the backend rest api
export default async function sendEmail(emailData) {
  try {
    const url =
      process.env.REACT_APP_IMSP_API_ENDPOINT + "/emailsender/sendemail";
    const response = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify(emailData)
    });

    if (response.ok) {
      return "An email was sent with the above information.";
    } else {
      throw new Error("Error: " + response.status + ";" + response.statusText);
    }
  } catch (error) {
    throw new Error(
      "An error occurred while sending email.",
      error.message
    );
  }
}

