import { useState, React } from "react";
import { Document, Page, pdfjs } from "react-pdf/dist/esm/entry.webpack5";
import PropTypes from "prop-types";

function PdfView({ pdfData }) {
  PdfView.propTypes = {
    pdfData: PropTypes.object.isRequired
  };

  pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

  const [numPages, setNumPages] = useState(null);
  //const [pageNumber] = useState(1);

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  //   const goToPrevPage = () =>
  //   setPageNumber(pageNumber - 1 <= 1 ? 1 : pageNumber - 1);

  // const goToNextPage = () =>
  //   setPageNumber(pageNumber + 1 >= numPages ? numPages : pageNumber + 1);

  return (
    <center>
      {/* <Document
                  file={{ data: pdfData }}
                  onLoadSuccess={onDocumentLoadSuccess}
                >
                  <Page pageNumber={pageNumber} renderTextLayer={false} />
                </Document>
                <p>
                  Page {pageNumber} of {numPages}
                </p> */}

      <Document file={{ data: pdfData }} onLoadSuccess={onDocumentLoadSuccess}>
        {Array.from(new Array(numPages), (el, index) => (
          <Page
            width={document.getElementById("root").clientWidth * 0.8}
            // scale={80.0}
            key={"page_" + index + 1}
            pageNumber={index + 1}
            renderTextLayer={false}
          />
        ))}
      </Document>
    </center>
  );
}

export default PdfView;
