import Container from "react-bootstrap/Container";
import Navbar from "react-bootstrap/Navbar";
import Image from "react-bootstrap/Image";
import { Globe } from "react-bootstrap-icons";
import { Nav } from "react-bootstrap";
import { useState, useContext, useEffect } from "react";
import { DeviceContext } from "./DeviceContext";

export default function Header() {
  const { deviceInfo, onLanguageOptionSelected } = useContext(DeviceContext);
  const [availableLanguages, setAvailableLanguages] = useState(undefined);
  const [languageSelected, setLanguageSelected] = useState(undefined);
  const [imageData, setImageData] = useState(null);

  useEffect(() => {
    if (
      deviceInfo !== undefined &&
      deviceInfo.availableLanguages !== undefined
    ) {
      var langOptionsList = [];
      deviceInfo.availableLanguages.map(function (languageOption) {
        langOptionsList.push({ key: languageOption.languageCode, text: languageOption.languageName });
      });

      setAvailableLanguages(langOptionsList);
      setLanguageSelected(deviceInfo.languageCode);

      let imageUri =
        process.env.REACT_APP_IMSP_API_ENDPOINT +
        "/clients/image/" +
        deviceInfo.countryCode +
        "/" +
        deviceInfo.clientCode;

      // Make the API request to retrieve the image data
      fetch(imageUri)
        .then((response) => response.blob())
        .then((data) => setImageData(URL.createObjectURL(data)))
        .catch((error) => {
          console.error("Error retrieving image data:", error);
        });
    }
  }, [deviceInfo]);

  return (
    <div className="header">
      <Navbar>
        <Container fluid>
          <Navbar.Brand className="ms-1">
            <Image src={(imageData === null || imageData === "") ? "/images/ingenico/ing_logo_001.png"
              : imageData} alt="logo" className="header-client-logo" />
          </Navbar.Brand>
          <Nav className="justify-content-end"></Nav>
          {availableLanguages !== undefined &&
            availableLanguages.length > 1 && (
              <Nav.Link className="d-flex">
                <Globe size={20} className="me-1" />

                <div className="no-border">
                  <select
                    className="no-border button-text"
                    value={languageSelected}
                    onChange={(e) => onLanguageOptionSelected(e.target.value)}
                  >
                    {availableLanguages.map((item) => {
                      return (
                        <option key={item.key} value={item.key}>
                          {item.text}
                        </option>
                      );
                    })}
                  </select>
                </div>
              </Nav.Link>
            )}
        </Container>
      </Navbar>
    </div>
  );
}
