import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { useContext } from "react";
import { PhoneFill } from "react-bootstrap-icons";
import { DeviceContext } from "./DeviceContext";

export default function SupportModal(props) {
  const { findLabelValue } = useContext(DeviceContext);

  const modalBlueButton = {
    minWidth: "80%",
    color: "white",
    textDecoration: "none",
    backgroundColor: "#19B6DD",
    borderRadius: "5px",
    borderColor: "#19B6DD"
  };

  const modalWhiteButton = {
    minWidth: "80%",
    color: "#19B6DD",
    textDecoration: "none",
    backgroundColor: "white",
    borderColor: "#19B6DD",
    border: "2px solid #19B6DD",
    borderRadius: "5px"
  };

  return (
    <div>
      <Modal
        {...props}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title className="ms-auto">
            {findLabelValue("pageSupportTitle")}
            <PhoneFill size={24} className="" />
          </Modal.Title>
        </Modal.Header>

        <Modal.Body className="text-center">
          <br></br>
          <p className="text-center">{findLabelValue("pageSupportBodyText")}</p>

          <br></br>
          <br></br>
          <Button
            href={"tel:" + findLabelValue("pageSupportTelephoneNo")}
            style={modalBlueButton}
          >
            {findLabelValue("pageSupportCallButton")}
          </Button>
          <br></br>
          <br></br>
          <Button style={modalWhiteButton}>
            {findLabelValue("pageSupportCallBackButton")}
          </Button>
          <br></br>
          <br></br>
        </Modal.Body>
      </Modal>
    </div>
  );
}
