export const DefaultLabels = [
  { languageCode: "en-AU", labelName: "footerFaqsTitle", lableValue: "FAQS" },
  { languageCode: "en-AU", labelName: "footerHelpTitle", lableValue: "Help" },
  { languageCode: "en-AU", labelName: "footerHomeTitle", lableValue: "Home" },
  {
    languageCode: "en-AU",
    labelName: "footerSupportTitle",
    lableValue: "Support",
  },
  {
    languageCode: "en-AU",
    labelName: "pageDeviceNotFound",
    lableValue: "Device not found",
  },
  {
    languageCode: "en-AU",
    labelName: "pageFaqNotFound",
    lableValue: "No FAQS found!",
  },
  {
    languageCode: "en-AU",
    labelName: "pageFaqSearchText",
    lableValue: "Search FAQ'S",
  },
  { languageCode: "en-AU", labelName: "pageFaqTitle", lableValue: "FAQ'S" },
  {
    languageCode: "en-AU",
    labelName: "pageHelpTitle",
    lableValue: "Comming soon!",
  },
  {
    languageCode: "en-AU",
    labelName: "pageHomeFaqButton",
    lableValue: "FAQ'S",
  },
  {
    languageCode: "en-AU",
    labelName: "pageHomeGetassistance",
    lableValue: "Get Assistance",
  },
  {
    languageCode: "en-AU",
    labelName: "pageHomePassage",
    lableValue:
      "Configure your terminal and learn how to get the most out of it",
  },
  {
    languageCode: "en-AU",
    labelName: "pageHomeTerminalClientID",
    lableValue: "Client ID",
  },
  {
    languageCode: "en-AU",
    labelName: "pageHomeTerminalCountry",
    lableValue: "Country",
  },
  {
    languageCode: "en-AU",
    labelName: "pageHomeTerminalDeviceType",
    lableValue: "Device Type",
  },
  {
    languageCode: "en-AU",
    labelName: "pageHomeTerminalLanguage",
    lableValue: "Language",
  },
  { languageCode: "en-AU", labelName: "pageHomeTitle", lableValue: "Welcome!" },
  { languageCode: "en-AU", labelName: "pageLoading", lableValue: "Loading..." },
  {
    languageCode: "en-AU",
    labelName: "pageSupportTitle",
    lableValue: "Call support",
  },
  {
    languageCode: "en-AU",
    labelName: "pageSupportBodyText",
    lableValue: "Choose your preferred option to contact us by phone",
  },
  {
    languageCode: "en-AU",
    labelName: "pageSupportTelephoneNo",
    lableValue: "+61410699591",
  },
  {
    languageCode: "en-AU",
    labelName: "pageSupportCallButton",
    lableValue: "Call Us ->",
  },
  {
    languageCode: "en-AU",
    labelName: "pageSupportCallBackButton",
    lableValue: "We call you Back",
  }
];
