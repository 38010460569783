import Container from "react-bootstrap/Container";
import FAQItem from "../components/FAQ";
import { useState, useContext, useEffect } from "react";
//import { useLocation } from "react-router-dom";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Accordion from "react-bootstrap/Accordion";
import Form from "react-bootstrap/Form";
import { DeviceContext } from "../components/DeviceContext";
//import queryString from "query-string";

export default function FAQS() {
  const [isLoading, setIsLoading] = useState(true);
  const [faqApiUrl, setFaqApiUrl] = useState(undefined);
  const [deviceFaqs, setDeviceFaq] = useState(undefined);
  const { deviceInfo, findLabelValue } = useContext(DeviceContext);

  const [searchTxt, setSearchTxt] = useState("");
  const [accordionKey, setAccordionKey] = useState("");

  const searchFaq = (text) => {
    setSearchTxt(text);
    //below is used to reset expanded accordion
    setAccordionKey((accordionKey) => accordionKey + 1);
    //setAccordionKey(0);
  };

  useEffect(() => {
    if (deviceInfo !== undefined && deviceInfo.productId !== undefined) {
      setFaqApiUrl(
        process.env.REACT_APP_IMSP_API_ENDPOINT +
          "/productfaqs/find/" +
          deviceInfo.countryCode +
          "/" +
          deviceInfo.clientCode +
          "/" +
          deviceInfo.productName +
          "/" +
          deviceInfo.languageCode
      );
    }

    const fetchData = async () => {
      try {
        const response = await fetch(faqApiUrl);
        const json = await response.json();
        setDeviceFaq(json);
        setIsLoading(false);
        //console.log("FAQS Loaded deviceFAQS " + json);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    if (faqApiUrl !== undefined) {
      fetchData();
    }
  }, [faqApiUrl, deviceInfo]);

  if (deviceInfo === undefined) {
    return (
      <div>
        <p>{findLabelValue("pageFaqNotFound")}</p>
      </div>
    );
  }

  if (isLoading === true) {
    return (
      <div>
        <p>{findLabelValue("pageLoading")}</p>
      </div>
    );
  }

  return (
    <div className="main-content">
      <br></br>
      {!isLoading && deviceFaqs && (
        <Container>
          <div>
            <Row>
              <Col>
                <b className="ingenico-color-darkblue">FAQ&apos;S</b>
                <Form>
                  <Form.Control
                    type="search"
                    placeholder={findLabelValue("pageFaqSearchText")}
                    onChange={(e) => searchFaq(e.target.value.toLowerCase())}
                    onKeyDown={(e) => {
                      e.key === "Enter" && e.preventDefault();
                    }}
                  />
                </Form>
              </Col>
            </Row>
            <br></br>
            <Row>
              <Col>
                <Accordion key={accordionKey}>
                  {deviceFaqs
                    .filter((faqItem) => {
                      return searchTxt.toLowerCase() === ""
                        ? faqItem
                        : faqItem.title.toLowerCase().includes(searchTxt);
                    })
                    .map((faqItem, index) => (
                      <Accordion.Item eventKey={index} key={index}>
                        <FAQItem index={index} faq={faqItem} />
                      </Accordion.Item>
                    ))}
                </Accordion>
              </Col>
            </Row>
          </div>
        </Container>
      )}
    </div>
  );
}
