import Header from "./components/Header";
import Footer from "./components/Footer";
import NotFound from "./pages/NotFound";
import { Routes, Route, useLocation } from "react-router-dom";
import Home from "./pages/Home";
import FAQS from "./pages/FAQS";
import IDR from "./pages/IDR";
import DeviceContextProvider from "./components/DeviceContext";
import { Worker } from "@react-pdf-viewer/core";
// The path to `package.json` can be changed depending the path of current file
import packageJson from "../package.json";

/* react bootstrap configs */
import "bootstrap/dist/css/bootstrap.min.css";

import "./App.css";
import "./fonts.css";




//import { useEffect } from "react";

// import Help from "./pages/Help";
// import ErrorPage from "./pages/ErrorPage";

function App() {
  const pdfjsVersion = packageJson.dependencies["pdfjs-dist"];

  <Worker
    workerUrl={`https://unpkg.com/pdfjs-dist@${pdfjsVersion}/build/pdf.worker.min.js`}
  >
    ...
  </Worker>;

  const location = useLocation();

  return (
    <div className="app">
      <DeviceContextProvider>
        {/* contains logo and language selection */}
        <Header />

        <Routes location={location} key={location.pathname}>
          {/* <Route exact path="/" element={<Home />} /> */}
          <Route path="/home" element={<Home />} />
          {/* <Route
            exact
            path="/home/:countryCode/:clientCode/:productName"
            element={<Home />}
          /> */}
          <Route
            exact
            path="/home/:countryCode/:clientCode/:productName/:languageCode?/:terminalId?"
            element={<Home />}
          />
          <Route path="/faqs" element={<FAQS />} />
          <Route
            exact
            path="/faqs/:countryCode/:clientCode/:productName/:languageCode/:terminalId?"
            element={<FAQS />}
          />
          {/*below is used for query stringf*/}
          <Route path="/idr" element={<IDR />} />
          <Route
            exact
            path="/idr/:countryCode/:clientCode/:productName/:languageCode/:terminalId?"
            element={<IDR />}
          />
          {/* <Route path="/help" element={<Help />} /> */}
          {/* <Route path="/support" element={<Support />} /> */}
          <Route path="*" element={<NotFound />} />
        </Routes>

        <Footer />
      </DeviceContextProvider>
    </div>
  );
}

export default App;
